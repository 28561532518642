/* || Color Variables */
$border-light: #cccccc;
$border-midi: #b7b7b7;
$border-dark: #000000;
$background-light: #cccccc;
$background-success: #def2e7;
$color-primary: #000;
$color-success: #208e4e;

/* || RESET */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: $color-primary;
}

/* || GENERAL STYLES */

@font-face {
  font-family: "Museo";
  src: url("../public/2A3F19_1_0.woff") format("woff");
}

body {
  font-family: "Museo" !important;
}

.h1 {
  font-size: 1.5em;
  font-weight: bold;
}

a {
  text-decoration: none;
}

select:focus {
  outline: none !important;
  border-color: $border-light !important;
  box-shadow: none !important;
}

textarea {
  resize: none;
}

/* || SITEWIDE */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-container {
  margin: 1em;
}

.btn {
  width: fit-content;
}

.btn:focus,
.cancel-btn:focus,
.change-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  border: 1px solid $border-light;
}

.form-control:focus {
  outline: none !important;
  border-color: $border-light !important;
  box-shadow: none !important;
}

.error-msg {
  border: 1px solid $border-dark;
  border-radius: 0.25em;
  background-color: $background-light;
  height: fit-content;
  width: fit-content;
}

.cancel-btn,
.cancel-btn:hover,
.change-btn,
.change-btn:hover {
  border-color: $border-midi;
  background-color: $background-light;
  color: $color-primary;
}

// .change-btn,
// .change-btn:hover {
//   border-color: $border-dark;
//   background-color: $background-light;
//   color: $color-primary;
// }

.m-right {
  margin-right: 1em;
}

/* LOGIN FORM */

.form-wrapper {
  max-width: 700px;
}

.form-container h1 {
  border-bottom: 1px solid $border-light;
}

/* || CUSTOMER CARD */

.support-bg {
  background-color: #66b2ff;
}

/* || ARTICLE LIST VIEW */

.check-container {
  min-width: 2em;
}

.checkbox {
  cursor: pointer;
  accent-color: $color-primary;
}

.article-img {
  width: 100%;
  height: 100px;
  width: 100px;
}

.card-content {
  min-width: 200px;
}

.reasons-dropdown {
  width: fit-content;
}

.form-select {
  border: 1px solid #cccccc;
}

.quantity-dropdown {
  width: fit-content;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.missing-reason {
  border: 1px solid $border-light;
}

.reason-alert {
  margin: 1em;
}

.card-container {
  display: flex;
  flex-direction: column;
}

.article-card {
  display: flex;
  flex-direction: column;
}

.hide {
  display: none;
}

.returnable-alert {
  width: 13em;
}

.returnable-alert p {
  font-size: 0.8em;
}

.not-returnable {
  margin-left: 1em;
}

ul {
  list-style-position: outside;
}

.btn-container {
  padding-left: 2em;
}

/* || RETURN LIST VIEW*/

.return-view {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.list-header {
  font-weight: bold;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-bottom: 0.5px solid $border-light;
}

.list-body {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.list-body > span {
  padding: 0.5em;
}

textarea {
  border: none;
}

.return-btns {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.request-btn {
  height: 60px;
}

/* || CONFIRMATION VIEW */

.checked-message {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.success-msg {
  border: 1px solid $color-success;
  border-radius: 0.25em;
  background-color: $background-success;
}

.success-msg p,
.success-msg h6 {
  color: $color-success;
}

.success-msg h6 {
  font-weight: bold;
}

/** 
Media queries for larger screens
*/

@media (min-width: 600px) {
  * {
    font-size: 14px;
  }

  .not-returnable {
    margin-left: 4em;
  }

  .card-container {
    flex-direction: row;
  }

  .reasons-dropdown {
    margin-left: 1em;
  }

  .dropdown-container {
    flex-direction: row;
  }

  .returnable-alert {
    height: fit-content;
    margin-left: 3em;
  }

  .return-btns {
    flex-direction: row;
  }
}

@media (min-width: 800px) {
  * {
    font-size: 14px;
  }

  .main-container {
    margin: 0;
  }

  .form-container {
    max-width: 400px;
    margin: 1em 0;
  }

  .customer-container,
  .list-container,
  .btn-container,
  .reason-alert,
  .return-view,
  .confirmation-container {
    max-width: 700px;
    margin: 1em 0;
  }
}

/* || Dev helpers */
// * {
//   outline: 1px solid purple;
// }
